<template>
  <div>
    <div class="d-flex flex-row justify-content-start flex-wrap tags">
      <div class="tag f-p4">
        <BIconCurrencyEuro class="tag-icon" />
        <span class="tag-text">
          <i18next :translation="$t('tagPriceADay')">
            <template #pricePerDay>
              {{ formatCurrency(product.spray.details.price / 30) }}
            </template>
          </i18next>
        </span>
      </div>
      <div class="tag f-p4">
        <BIconTruck class="tag-icon" />
        <span class="tag-text">{{ $t("deliveryCosts") }}</span>
      </div>
      <div class="tag f-p4">
        <BIconClockHistory class="tag-icon" />
        <span class="tag-text">{{ $t("durationSpray") }}</span>
      </div>
      <div v-if="vegan" class="tag f-p4">
        <BIconFlower1 class="tag-icon" />
        <span class="tag-text">{{ $t("vegan") }}</span>
      </div>
    </div>
    <div class="img-container position-relative text-center">
      <div class="position-absolute btn-share">
        <b-button @click="$emit('share-action')" href="#" variant="link">
          <BIconBoxArrowUp />
        </b-button>
      </div>
      <div
        v-if="
          product.spray.details.images &&
          product.spray.details.images.length > 0
        "
        class="pl-4 pr-4"
      >
        <b-aspect aspect="1:1">
          <div class="position-absolute prev-btn">
            <b-button @click="prev()" href="#" variant="link">
              <BIconChevronLeft />
            </b-button>
          </div>
          <b-carousel v-model="active_img" ref="imgCarousel">
            <b-carousel-slide
              v-if="product.spray.type == 'Spray'"
              :img-src="product.spray.details.images[0]"
              class="generated-image"
            >
              <canvas
                id="generated-image-slider"
                class="h-100"
                @click="modal_img = 'g'"
              ></canvas>
            </b-carousel-slide>
            <b-carousel-slide
              v-for="(img, i) in product.spray.details.images"
              :key="'img-' + i"
              :img-src="img.includes('|') ? img.split('|')[1] : img"
            >
              <div
                class="w-100 h-100"
                @click="
                  img.includes('|')
                    ? $bvModal.show('video-modal-' + i)
                    : (modal_img = i)
                "
              />
            </b-carousel-slide>
          </b-carousel>
          <div class="position-absolute next-btn">
            <b-button @click="next()" href="#" variant="link">
              <BIconChevronRight />
            </b-button>
          </div>
        </b-aspect>

        <div
          v-if="product.spray.type == 'Spray'"
          class="img-modal"
          :class="modal_img == 'g' ? 'active' : ''"
          @click="modal_img = null"
        >
          <div class="img-modal-lightbox">
            <canvas id="generated-image-modal"></canvas>
          </div>
          <div class="modal-backdrop" />
        </div>
        <template v-for="(img, i) in product.spray.details.images">
          <b-modal
            v-if="img.includes('|')"
            :key="'video-modal-' + i"
            :id="'video-modal-' + i"
            centered
            size="xl"
            hide-footer
            hide-header
          >
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="img.split('|')[0]"
              allowfullscreen
            ></b-embed>
          </b-modal>
          <div
            v-else
            :key="'img-modal-' + i"
            class="img-modal"
            :class="modal_img == i ? 'active' : ''"
            @click="modal_img = null"
          >
            <div class="img-modal-lightbox">
              <b-img :src="img" />
            </div>
            <div class="modal-backdrop" />
          </div>
        </template>
        <b-container fluid class="p-2">
          <b-row>
            <b-col v-if="product.spray.type == 'Spray'" class="pl-1 pr-1">
              <b-aspect aspect="1:1">
                <b-img
                  fluid
                  thumbnail
                  src="@/assets/spray-empty.png"
                  :class="active_img == 0 ? 'active' : ''"
                  @click="active_img = 0"
                  class="preview-img"
                ></b-img>
              </b-aspect>
            </b-col>
            <b-col
              v-for="(img, i) in product.spray.details.images"
              :key="'tn-' + i"
              class="pl-1 pr-1"
            >
              <b-aspect aspect="1:1">
                <b-img
                  fluid
                  thumbnail
                  :src="img.includes('|') ? img.split('|')[1] : img"
                  :class="
                    active_img == i + (product.spray.type == 'Spray' ? 1 : 0)
                      ? 'active'
                      : ''
                  "
                  @click="
                    active_img = i + (product.spray.type == 'Spray' ? 1 : 0)
                  "
                  class="preview-img"
                ></b-img>
              </b-aspect>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-else class="bottle-background">
        <canvas id="generated-image-single"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BIconBoxArrowUp,
  BIconCurrencyEuro,
  BIconTruck,
  BIconClockHistory,
  BIconFlower1,
  BIconChevronLeft,
  BIconChevronRight,
} from "bootstrap-vue";

export default {
  name: "ProductDetailImageCarousel",
  components: {
    BIconBoxArrowUp,
    BIconCurrencyEuro,
    BIconTruck,
    BIconClockHistory,
    BIconFlower1,
    BIconChevronLeft,
    BIconChevronRight,
  },
  props: ["recipe", "product", "firstname", "vegan"],
  i18nOptions: { keyPrefix: "productDetail" },
  data: function () {
    return {
      active_img: 0,
      modal_img: null,
    };
  },
  mounted() {
    let bottle_img = new Image();
    bottle_img.onload = this.onloadEmptySpray;
    bottle_img.src = require("@/assets/spray-empty.png");

    document.documentElement.addEventListener("keydown", this.escEventListener);
  },
  destroyed() {
    document.documentElement.removeEventListener(
      "keydown",
      this.escEventListener
    );
  },
  methods: {
    prev() {
      this.$refs.imgCarousel.prev();
    },
    next() {
      this.$refs.imgCarousel.next();
    },
    escEventListener: function (e) {
      if (e.key === "Escape") {
        this.modal_img = null;
      }
    },
    onloadEmptySpray: function (e) {
      this.addGeneratedImageToCanvas(e.srcElement, "generated-image-slider");
      this.addGeneratedImageToCanvas(e.srcElement, "generated-image-modal");
      this.addGeneratedImageToCanvas(
        e.srcElement,
        "generated-image-single",
        false
      );
    },
    addGeneratedImageToCanvas: function (img, id, background = true) {
      let canvas = document.getElementById(id);
      if (canvas) {
        if (background) {
          canvas.width = img.naturalHeight + 100;
          canvas.height = img.naturalHeight + 100;
        } else {
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
        }
        let ctx = canvas.getContext("2d");
        if (background) {
          ctx.fillStyle = "rgb(250, 244, 237)";
          ctx.strokeStyle = ctx.fillStyle;
          ctx.beginPath();
          ctx.roundRect(0, 0, canvas.width, canvas.height, 12);
          ctx.stroke();
          ctx.fill();
        }
        ctx.drawImage(
          img,
          (canvas.width - img.naturalWidth) / 2,
          (canvas.height - img.naturalHeight) / 2,
          img.naturalWidth,
          img.naturalHeight
        );

        let fsize = img.naturalHeight * 0.0625;
        let top =
          (canvas.height - img.naturalHeight) / 2 +
          img.naturalHeight * 0.703125;
        let width = img.naturalWidth * 0.525;
        let left =
          (canvas.width - img.naturalWidth) / 2 +
          img.naturalWidth / 2 -
          img.naturalWidth * 0.00625;
        ctx.font = "normal 700 " + fsize + "px sans-serif";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        if (this.product.spray.type == "Spray") {
          ctx.fillText(this.recipe.mixture_num, left, top, width);

          if (this.firstname) {
            ctx.fillText(this.firstname, left, top + fsize * 1.5, width);
          } else if (this.recipe.scope) {
            ctx.fillText(this.recipe.scope, left, top + fsize * 1.5, width);
          }
        } else {
          let names = this.product.spray.num.split(" ");
          if (names.length > 2) {
            ctx.fillText(names[0], left, top, width);
            ctx.fillText(names[1], left, top + fsize, width);
            ctx.fillText(names[2], left, top + fsize * 2, width);
          } else if (names.length > 1) {
            ctx.fillText(names[0], left, top, width);
            ctx.fillText(names[1], left, top + fsize, width);
          } else {
            ctx.fillText(this.product.spray.num, left, top, width);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.img-container .b-aspect > div,
.img-container .carousel-inner {
  display: flex;
  align-items: center;
}
.img-container .carousel-caption div,
.img-container .carousel-caption canvas {
  cursor: pointer;
}
.img-container .carousel-control-prev,
.img-container .carousel-control-next {
  z-index: 10 !important;
}
.img-container .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23bbcc4b%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e");
}
.img-container .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23bbcc4b%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");
}
.img-container .carousel-item > img {
  aspect-ratio: 1/1;
  object-fit: cover;
}
.img-container .carousel-item.generated-image > img {
  visibility: hidden;
}
.img-container .img-modal {
  display: none;
}
.img-container .img-modal.active {
  display: block;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
}
.img-container .img-modal.active .img-modal-lightbox {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 10vh 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-container .img-modal.active img,
.img-container .img-modal.active canvas {
  max-width: 100%;
  max-height: 100%;
}
.img-container .img-thumbnail {
  cursor: pointer;
}
.img-container .img-thumbnail.active {
  border: 1px solid var(--zimply-black);
}
.preview-img {
  aspect-ratio: 3/2;
  object-fit: contain;
}
.btn-share {
  top: 0;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  z-index: 10;
}
.img-container {
  .prev-btn {
    top: 40%;
    left: 5px;
    z-index: 10;
  }
  .next-btn {
    top: 40%;
    right: 5px;
    z-index: 10;
  }
  .bottle-background {
    padding: 20px 20px;
    margin-right: 25px;
    border-radius: 10px;
    background-color: var(--zimply-beige);
  }
  #generated-image-single {
    height: 20rem;
  }
  @media only screen and (max-width: 576px) {
    #generated-image-single {
      height: calc(20rem * 0.9);
    }
  }
}
.tags {
  gap: 5px;

  .tag {
    background-color: var(--zimply-dark-green);
    border-radius: 10px;
    gap: 10px;
    padding-left: 5px;
    padding-right: 8px;
    padding-block: 3px;

    .tag-icon {
      color: var(--zimply-beige);
      margin-right: 3px;
    }

    .tag-text {
      color: var(--zimply-beige);
    }
  }
}
</style>

<template>
  <div class="main-wrapper">
    <div v-if="$ZNC_ENV != 'PROD'" class="env-hint">== {{ $ZNC_ENV }} ==</div>
    <div class="main" :class="transition.direction">
      <TheNavbar />
      <transition
        name="t-fade"
        @before-enter="beforeTransitionEnter"
        @after-enter="afterTransitionEnter"
      >
        <router-view />
      </transition>

      <ModalTimeout></ModalTimeout>
      <ModalError :message="error_message"></ModalError>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar.vue";
import TheFooter from "@/components/TheFooter.vue";
import ModalTimeout from "@/components/ModalTimeout.vue";
import ModalError from "@/components/ModalError.vue";

export default {
  name: "App",
  components: {
    TheNavbar,
    TheFooter,
    ModalTimeout,
    ModalError,
  },
  metaInfo: function () {
    let links = [];
    ["de", "en", "es", "fr", "it"].forEach((lang) => {
      links.push({
        rel: "alternate",
        hreflang: lang,
        href: window.location.href.replace(
          "/" + this.$i18next.resolvedLanguage + "/",
          "/" + lang + "/"
        ),
      });
    });
    return {
      htmlAttrs: {
        lang: this.$i18next.resolvedLanguage,
      },
      meta: [
        { property: "og:locale", content: this.$i18next.resolvedLanguage },
        { property: "og:site_name", content: "ZIMPLY NATURAL" },
        { name: "description", content: this.$t("main.metadesc") },
      ],
      link: links,
      title: this.$t("main.title") + " - ZIMPLY NATURAL",
    };
  },
  data: function () {
    return {
      error_message: null,
      transition: {
        direction: "foreward",
      },
    };
  },
  created: async function () {
    this.$EventBus.$on("show_error", this.show_error);
    this.$EventBus.$on("gtag", this.gtag);
    this.$EventBus.$on("gtag_ecom", this.gtag_ecom);
    this.$EventBus.$on("track_goal", this.track_goal);
    this.$EventBus.$on("track_abtest", this.track_abtest);
    this.$EventBus.$on("execute_abtest", this.execute_abtest);
    this.$EventBus.$on("set_partner", this.setPartner);
    this.$EventBus.$on("checkout_product", this.checkoutProduct);
    this.$EventBus.$on("transition_direction", this.setTransitionDirection);
    try {
      if (localStorage.getItem("znc_cart")) {
        let loc_cart = JSON.parse(localStorage.getItem("znc_cart"));
        if (Array.isArray(loc_cart)) {
          loc_cart = await this.updateLocalCart(loc_cart);
          this.$cart.splice(0, this.$cart.length);
          this.$cart.push(...loc_cart);
        }
      }
    } catch (error) {
      console.log("Cannot access localStorage!");
    }

    if (this.$ZNC_ENV != "PROD") {
      document.title = this.$ZNC_ENV + " " + document.title;
    }
  },
  watch: {
    $cart: {
      handler() {
        this.$cart.forEach((item) => {
          if (!item.watcher) {
            item.watcher = this.$watch(() => item, this.handleCartItemChange, {
              immediate: true,
              deep: true,
            });
          }
        });
        try {
          localStorage.setItem("znc_cart", JSON.stringify(this.$cart));
        } catch (error) {
          console.log("Cannot access localStorage!");
        }
      },
      deep: true,
    },
  },
  methods: {
    handleCartItemChange: function (item, force = false) {
      if (!item.product) return;

      let lastCount = 0;
      if (item.lastCount) {
        lastCount = item.lastCount;
      }
      let discountp = this.getQuantityDiscount(item.count);
      if (item.subscription_interval) {
        discountp = 20;
      }
      let price = this.round(
        item.product.price - (item.product.price * discountp) / 100
      );
      if (item.product.bundle) {
        discountp = 0;
        price = item.product.bprice;
      }

      if (
        item.count != lastCount ||
        item.discountp != discountp ||
        item.price != price ||
        force
      ) {
        item.discountp = discountp;
        item.price = price;
        item.baseprice = this.calcBasePrice(item.product, item.price);
        item.sum = item.price * item.count;

        item.lastCount = item.count;

        let itemClone = { ...item };
        if (lastCount < item.count) {
          itemClone["count"] = item.count - lastCount;
          this.$EventBus.$emit("gtag_ecom", {
            event: "addToCart",
            ecommerce: {
              currencyCode: "EUR",
              add: {
                products: [itemClone],
              },
            },
            cart: this.$cart,
          });
        } else if (lastCount > item.count) {
          itemClone["count"] = lastCount - item.count;
          this.$EventBus.$emit("gtag_ecom", {
            event: "removeFromCart",
            ecommerce: {
              currencyCode: "EUR",
              remove: {
                products: [itemClone],
              },
            },
            cart: this.$cart,
          });
        }
      }
    },
    updateLocalCart: function (loc_cart) {
      return new Promise((resolve) => {
        let promises = [];
        loc_cart.forEach(async (item) => {
          if (!item.product) {
            await this.$sessionAvailable;
            promises.push(
              fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  product: item.id,
                  info: "cart-upgrade",
                  lang: this.$i18next.resolvedLanguage,
                }),
              })
                .then((response) =>
                  Promise.all([response.status, response.json()])
                )
                .then(([status, result]) => {
                  if (status == 200) {
                    item.product = result;
                    if (result.product && result.product.spray) {
                      item.product = result.product.spray;
                    }
                    this.handleCartItemChange(item, true);
                  }
                })
            );
          }
        });
        Promise.all(promises).then(() => {
          resolve(loc_cart);
        });
      });
    },
    checkoutProduct: function (
      product,
      quantity,
      subscription_interval = null,
      firstname = null
    ) {
      let item = {
        product: product,
        id: product.num,
        type: product.type,
        name: product.details.iname
          ? product.details.iname
              .replace(
                "<Individualisierung>",
                firstname
                  ? this.$t("productDetail.firstnameProductPrefix") +
                      " " +
                      firstname
                  : product.scope
                  ? "(" + product.scope + ")"
                  : ""
              )
              .replace(
                this.$t("productDetail.firstnameProductPrefix") + " <Vorname>",
                firstname
                  ? this.$t("productDetail.firstnameProductPrefix") +
                      " " +
                      firstname
                  : product.scope
                  ? "(" + product.scope + ")"
                  : ""
              )
              .replace("<Mischung>", product.mixture)
          : product.details.name,
        firstname: firstname,
        deliverytime: product.details.deliverytime,
        count: quantity,
        price: product.price,
        sum: product.price * quantity,
        code: product.code,
        branch: product.indication,
        mixture: product.mixture,
      };
      item.product.details = {
        iname: item.product.details.iname,
        name: item.product.details.name,
        price: item.product.details.price,
        volume: item.product.details.volume,
      };
      if (subscription_interval) {
        item.subscription_interval = subscription_interval;
        item.name =
          this.$t("productDetail.subscriptionProductPrefix", {
            interval: subscription_interval,
          }) +
          " " +
          item.name;
      }
      if (item.product.bundle) {
        item.name = item.product.bundle.name + ": " + item.name;
      }

      this.$cart.push(item);
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: product.indication,
        schritt:
          (subscription_interval ? "ABO:" : "") + item.type.toLowerCase(),
        teilschritt: item.count,
      });
      this.$EventBus.$emit("track_goal", 3);
    },
    setPartner: function (partner) {
      this.$order_info.partner = partner;
    },
    show_error: function (message) {
      this.error_message = message;
      this.$bvModal.show("error-modal");
    },
    gtag: function (message) {
      let new_message = {
        event: null,
        indikation: null,
        schritt: null,
        teilschritt: null,
        value: null,
      };
      for (let key in message) {
        if (message[key] && typeof message[key].toLowerCase === "function") {
          new_message[key] = message[key]
            .toLowerCase()
            .replace(/ä/g, "ae")
            .replace(/ü/g, "ue")
            .replace(/ö/g, "oe")
            .replace(/ß/g, "ss");
        } else {
          new_message[key] = message[key];
        }
      }

      window._paq = window._paq || [];
      window._paq.push([
        "trackEvent",
        new_message.event,
        new_message.schritt,
        new_message.teilschritt ? new_message.teilschritt : "",
        new_message.value ? new_message.value : "",
        new_message.indikation ? { dimension1: new_message.indikation } : {},
      ]);
    },
    gtag_ecom: function (message) {
      let new_message = {
        event: message["event"],
        ecommerce: this.prepareEcommerceMessage(message["ecommerce"]),
      };
      window._mtm = window._mtm || [];
      window._mtm.push(new_message);

      window._paq = window._paq || [];
      let sum = 0;
      if (
        message.event == "addToCart" ||
        message.event == "removeFromCart" ||
        message.event == "purchase"
      ) {
        message["cart"].forEach((position) => {
          if (position.count == 0) {
            let new_message = [
              "removeEcommerceItem",
              position.id + (position.mixture ? "/" + position.mixture : ""),
              position.branch ? { dimension1: position.branch } : {},
            ];
            window._paq.push(new_message);
          } else {
            let new_message = [
              "addEcommerceItem",
              position.id + (position.mixture ? "/" + position.mixture : ""),
              position.type,
              position.type,
              position.price,
              position.count,
              position.branch ? { dimension1: position.branch } : {},
            ];
            window._paq.push(new_message);
            sum += position.price * position.count;
          }
        });
      }
      if (message.event == "addToCart" || message.event == "removeFromCart") {
        let new_message = ["trackEcommerceCartUpdate", this.round(sum)];
        window._paq.push(new_message);
      } else if (message.event == "purchase") {
        let new_message = [
          "trackEcommerceOrder",
          message.ecommerce.purchase.actionField.id,
          message.ecommerce.purchase.actionField.revenue,
          message.ecommerce.purchase.actionField.revenue,
          message.ecommerce.purchase.actionField.tax,
          0,
          message.ecommerce.purchase.actionField.discount,
        ];
        window._paq.push(new_message);
      }
    },
    prepareEcommerceMessage: function (message) {
      for (let value of Object.values(message)) {
        if (typeof value === "object" && "products" in value) {
          let products = value["products"];
          let p = [];
          products.forEach((product) => {
            p.push({
              id: product["id"],
              price: this.round(product["price"] / 1.19),
              category: product["type"],
              variant: product["branch"],
              quantity: product["count"],
            });
          });
          value["products"] = p;
        }
      }
      return message;
    },
    track_goal: function (goal, revenue) {
      let message = ["trackGoal", goal];
      if (revenue) message.push(revenue);
      window._paq = window._paq || [];
      window._paq.push(message);
    },
    track_abtest: function (experiment, variation) {
      let message = [
        "AbTesting::enter",
        {
          experiment: experiment,
          variation: variation,
        },
      ];
      window._paq = window._paq || [];
      window._paq.push(message);
    },
    execute_abtest: function (experiment, variations) {
      let message = [
        "AbTesting::create",
        {
          name: experiment,
          variations: variations,
        },
      ];
      window._paq = window._paq || [];
      window._paq.push(message);
    },
    beforeTransitionEnter: function () {
      this.$EventBus.$emit("transition", "start");
    },
    afterTransitionEnter: function () {
      this.$EventBus.$emit("transition", "end");
      this.transition.direction = "foreward";
    },
    setTransitionDirection: function (direction) {
      this.transition.direction = direction;
    },
    calcBasePrice: function (product, price) {
      if (product.details.volume) {
        return (
          this.formatNumber((price / product.details.volume) * 1000) + " €/l"
        );
      }
      return null;
    },
  },
};
</script>

<style>
:root {
  --green: rgb(164, 242, 190);
  --red: rgb(248, 118, 118);
  --red-t50: rgba(248, 118, 118, 0.5);
  --red-dark: rgb(228, 98, 98);
  --blue: rgb(61, 74, 90);
  --invalid: rgb(220, 53, 69);
  --gray: rgb(222, 226, 230);
  --disabled-gray: rgb(233, 236, 239);
  --icon-gray: rgb(33, 37, 41);
  --star-yellow: rgb(226, 176, 7);
  --zimply-beige: rgb(250, 244, 237);
  --zimply-beige-t75: rgb(250, 244, 237, 0.75);
  --zimply-brown: rgb(191, 178, 170);
  --zimply-dark-green: rgb(59, 64, 24);
  --zimply-light-green: rgb(187, 204, 75);
  --zimply-light-green-t80: rgb(187, 204, 75, 0.8);
  --zimply-black: rgb(9, 16, 6);
  --zimply-black-t60: rgb(9, 16, 6, 0.6);
  --zimply-drak-blue: rgb(30, 64, 64);
  --zimply-lighter-green: rgb(243, 247, 224);
  --text-nunito: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --text-montserrat: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1,
.f-h1 {
  font-size: 1.8125rem; /* 29px */
}
h2,
.f-h2 {
  font-size: 1.625rem; /* 26px */
}
.f-p1,
.btn.btn-lg {
  font-size: 1.3125rem; /* 21px */
}
.f-p2,
h3,
.f-h3,
.btn-primary,
.btn-outline-primary.primsize,
.btn-secondary.primsize,
.btn-outline-secondary.primsize,
.modal-footer .btn-secondary {
  font-size: 1.1875rem; /* 19px */
}
.f-p3,
.f-p3m2,
.btn-primary.secsize,
.btn-primary.cart-button,
.btn-secondary,
.btn-outline-secondary,
.btn-outline-primary.secsize {
  font-size: 1.0625rem; /* 17px */
}
.f-p4 {
  font-size: 0.8125rem; /* 13px */
}
h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: var(--text-montserrat);
  font-weight: 700;
}
@media only screen and (max-width: 576px) {
  h1,
  .f-h1 {
    font-size: 1.5625rem; /* 25px */
  }
  h2,
  .f-h2 {
    font-size: 1.375rem; /* 22px */
  }
  .f-p1,
  .btn.btn-lg {
    font-size: 1.0625rem; /* 17px */
  }
  .f-p2,
  .f-p3m2,
  h3,
  .f-h3,
  .btn-primary,
  .btn-secondary.primsize,
  .btn-outline-secondary.primsize,
  .btn-outline-primary.primsize,
  .btn-secondary.primsize,
  .modal-footer .btn-secondary {
    font-size: 0.9375rem; /* 15px */
  }
  .f-p3,
  .btn-primary.secsize,
  .btn-primary.cart-button,
  .btn-secondary,
  .btn-outline-secondary,
  .btn-outline-primary.secsize {
    font-size: 0.8125rem; /* 13px */
  }
  .f-p4 {
    font-size: 0.625rem; /* 10px */
  }
}

html {
  min-height: 100%;
  position: relative;
  scroll-padding-top: 110px;
}
body {
  height: 100vh;
  overflow-x: hidden;
  overscroll-behavior-y: none; /* Chrome: disable pull-to-refresh */
  font-family: var(--text-nunito);
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* Safari: disable pull-to-refresh */
    body {
      overflow-y: hidden;
    }
    #touch-wrapper {
      height: 100vh;
      overflow-y: auto;
    }
  }
}
@media only screen and (min-width: 576px) {
  /* PC: disable scrollbar during transition */
  body {
    overflow-y: hidden;
  }
  #touch-wrapper {
    height: 100vh;
    overflow-y: auto;
    scroll-padding-top: 110px;
  }
}
.main-wrapper {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex: 1;
}
.start,
.question-wrapper,
.mixture-wrapper,
.checkout-wrapper,
.thankyou-wrapper,
.gift-voucher-wrapper {
  width: 100vw;
}

.header {
  text-align: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-radius: 10px;
}
.header > h1 {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
}
h1 {
  font-weight: 400;
  line-height: 1.5;
  color: var(--zimply-black);
  font-family: var(--text-montserrat);
}
h2 {
  font-weight: 700;
}
img {
  border-radius: 10px;
}
a {
  color: var(--zimply-brown);
  text-decoration: none;

  &:hover {
    color: var(--zimply-light-green);
    text-decoration: none;
  }
}

.sidebar,
.banner {
  background-color: var(--zimply-beige);
  border-radius: 10px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--zimply-dark-green);
  border-color: var(--zimply-dark-green);
  color: var(--zimply-beige);
  font-weight: 700;
}
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--zimply-beige);
  border-color: var(--zimply-beige);
  color: var(--zimply-black);
}

.btn-primary.cart-button,
.btn-primary.cart-button.disabled,
.btn-primary.cart-button:disabled {
  background-color: var(--zimply-light-green);
  border-color: var(--zimply-light-green);
  color: var(--zimply-black);
  font-weight: 700;
  font-family: var(--text-montserrat);
  text-transform: uppercase;
}
.btn-primary.cart-button:hover,
.btn-primary.cart-button.focus,
.btn-primary.cart-button:focus,
.btn-primary.cart-button:not(:disabled):not(.disabled).active,
.btn-primary.cart-button:not(:disabled):not(.disabled):active {
  background-color: var(--zimply-brown);
  border-color: var(--zimply-brown);
  color: var(--zimply-black);
}

.btn-primary.focus,
.btn-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-outline-primary.active.focus,
.btn-outline-primary.active:focus,
.btn-outline-primary:active.focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.2rem var(--red-t50);
}
.btn-outline-primary,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: var(--zimply-dark-green);
  border-color: var(--zimply-dark-green);
}
.btn-outline-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary.active,
.btn-outline-primary:active {
  background-color: var(--zimply-light-green);
  border-color: var(--zimply-light-green);
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--zimply-dark-green);
  background-color: var(--zimply-dark-green);
}
.text-link {
  color: var(--zimply-brown);
  font-weight: normal;
}
.text-link:hover {
  color: var(--zimply-light-green);
  text-decoration: none;
}

.navbar .btn-outline-dark {
  background-color: unset;
}
.btn {
  border-radius: 10px;
}
.btn-config {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: var(--zimply-beige);
  color: var(--zimply-black-t60);
}

.btn-green {
  background-color: var(--zimply-dark-green);
  color: var(--zimply-beige);
  text-transform: uppercase;
  font-family: var(--text-montserrat);
  font-weight: 700;
  border: none;
  border-radius: 10px;
}
.btn-green:hover {
  background-color: var(--zimply-brown);
  color: var(--zimply-black);
}
.btn-light {
  background-color: var(--zimply-beige);
  color: var(--zimply-dark-green);
  text-transform: uppercase;
  font-family: var(--text-montserrat);
  font-weight: 700;
  border: none;
  border-radius: 10px;
}
.btn-light:hover {
  background-color: var(--zimply-brown);
  color: var(--zimply-black);
}

.btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.hover-brown:hover {
  background-color: var(--zimply-brown);
  color: var(--zimply-black);
}

.env-hint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2000;
  background-color: rgb(255, 216, 0, 0.8);
  text-align: center;
  user-select: none;
}

/* Transition */
.t-ud-enter-active,
.t-ud-leave-active,
.t-fade-enter-active,
.t-fade-leave-active {
  position: absolute;
  transition: all 1s;
}
.t-ud-enter,
.t-ud-leave-to,
.t-fade-enter,
.t-fade-leave-to {
  opacity: 0;
}
.foreward .t-ud-enter,
.backward .t-ud-leave-active {
  transform: translateY(100vh);
}
.foreward .t-ud-leave-active,
.backward .t-ud-enter {
  transform: translateY(-100vh);
}
.main:has(.t-ud-enter-active) ~ span .footer,
.main:has(.t-ud-leave-active) ~ span .footer,
.main:has(.t-fade-enter-active) ~ span .footer,
.main:has(.t-fade-leave-active) ~ span .footer {
  visibility: hidden;
}
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1400px) {
  .container-xxl {
    max-width: 1320px;
  }
}
.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.img-container {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
}
.mw-content {
  max-width: fit-content;
}
.rounded {
  border-radius: 10px;
}
.modal-content {
  border-radius: 10px;
}
.lh-1 {
  line-height: 1 !important;
}

@media only screen and (min-width: 576px) {
  .display-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 576px) {
  .display-desktop {
    display: none !important;
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/vue/styles";
.main-wrapper {
  //@include generate-color-variants(--_c-red-primary, rgb(248, 118, 118));
  @include assign-color-variants(--c-primary, --zimply-dark-green);
  @include assign-color-variants(--c-primary-variant, --zimply-dark-green);
  --font-family--primary: Montserrat, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family--secondary: Nunito, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>

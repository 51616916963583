<template>
  <div
    class="questionary"
    :class="curr_step ? curr_step.step.replace(/#/g, '') : ''"
  >
    <transition
      name="t-ud"
      @before-enter="beforeTransition"
      @before-leave="beforeTransition"
      @after-enter="afterTransitionEnter"
      @after-leave="afterTransitionLeave"
    >
      <template>
        <QuestionStart
          :key="curr_step.question.id"
          :step="curr_step"
          :running="running"
          @next_without="next_without"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'start'
          "
        ></QuestionStart>
        <QuestionOption
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'option'
          "
        ></QuestionOption>
        <QuestionOptionMultiselect
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'option-multiselect'
          "
        ></QuestionOptionMultiselect>
        <QuestionInput
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :error="error"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'input'
          "
        ></QuestionInput>
        <QuestionConfirm
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :error="error"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'confirm'
          "
        ></QuestionConfirm>
        <QuestionRangeSlider
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :error="error"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'range-slider'
          "
        ></QuestionRangeSlider>
        <QuestionCircleSlider
          :key="curr_step.question.id"
          :step="curr_step"
          :answers="answers"
          :error="error"
          :running="running"
          @answer_and_next="answer_and_next"
          v-if="
            curr_step &&
            curr_step.question &&
            curr_step.question.type == 'circle-slider'
          "
        ></QuestionCircleSlider>

        <ProductDetail
          v-if="curr_step && curr_step.step == '##SHOW_MIXTURE##'"
          :recipe="recipe"
          :product="product"
          :firstname="recipe.input.Vorname"
          :branch="branch"
          :hasInput="true"
        ></ProductDetail>
      </template>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import QuestionStart from "@/components/QuestionStart.vue";
import QuestionConfirm from "@/components/QuestionConfirm.vue";
import QuestionInput from "@/components/QuestionInput.vue";
import QuestionOption from "@/components/QuestionOption.vue";
import QuestionOptionMultiselect from "@/components/QuestionOptionMultiselect.vue";
import QuestionRangeSlider from "@/components/QuestionRangeSlider.vue";
import QuestionCircleSlider from "@/components/QuestionCircleSlider.vue";
import ProductDetail from "@/components/ProductDetail.vue";

export default {
  name: "QuestionaryView",
  components: {
    QuestionStart,
    QuestionConfirm,
    QuestionInput,
    QuestionOption,
    QuestionOptionMultiselect,
    QuestionRangeSlider,
    QuestionCircleSlider,
    ProductDetail,
  },
  props: ["branch"],
  data: function () {
    return {
      answers: {},
      recipe: null,
      product: null,
      history: [],
      curr_step: null,
      error: null,
      touch: {
        active: false,
        startX: null,
        startY: null,
        startTime: null,
        debug: null,
      },
      running: false,
      wait_timer: null,
    };
  },
  created: async function () {
    this.answers = { branch: this.branch };
    this.history = [];
    if (this.$route.params.init_step) {
      this.curr_step = this.$route.params.init_step;
      this.loadProductInfo();
    } else {
      if (this.$route.params.fromStart) {
        this.curr_step = { step: "Start" };
      }
      await this.$sessionAvailable;
      this.nextStep();
    }
    this.$EventBus.$on("go_back", this.go_back);
    this.$EventBus.$on("go_start", this.go_start);
    document.addEventListener("touchstart", this.touchstart, { passive: true });
    document.addEventListener("touchend", this.touchend, { passive: true });
    this.$EventBus.$emit("hideChatWidget", true);
  },
  destroyed: function () {
    this.$EventBus.$off("go_back", this.go_back);
    this.$EventBus.$off("go_start", this.go_start);
    this.$EventBus.$emit("update_progress", null, null);
    document.removeEventListener("touchstart", this.touchstart);
    document.removeEventListener("touchend", this.touchend);
    this.$EventBus.$emit("showChatWidget", true);
  },
  watch: {
    "$i18next.resolvedLanguage": function () {
      this.nextStep();
    },
  },
  methods: {
    next_without: function () {
      this.history.push(this.curr_step);
      this.nextStep();
    },
    answer_and_next: function (answer, wait = 0) {
      if (this.wait_timer) {
        clearTimeout(this.wait_timer);
      }
      if (answer == undefined || answer == "not_accepted") {
        this.error = true;
        return;
      }

      this.error = false;
      this.answers[this.curr_step.step] = answer;
      this.history.push(this.curr_step);
      this.wait_timer = setTimeout(() => {
        this.nextStep();
      }, wait);
    },
    nextStep: async function () {
      this.running = true;
      let context = {
        /*global ZNC_KEY*/
        key: ZNC_KEY,
        branch: this.branch,
        lastStep: this.curr_step ? this.curr_step.step : null,
        input: this.answers,
        lang: this.$i18next.resolvedLanguage,
      };
      if (context.lastStep == "Start") {
        this.$EventBus.$emit("track_goal", 1);
      }
      let response = await fetch(
        this.$ZNC_BACKEND_BASE + "/business/v1/next-step",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(context),
        }
      );
      if (response.ok) {
        this.curr_step = await response.json();
        this.$EventBus.$emit(
          "update_progress",
          this.curr_step.index,
          this.curr_step.count
        );
        if (this.curr_step.step == "##END##") {
          this.loadProductInfo();
          this.$EventBus.$emit("track_goal", 2);
          this.$EventBus.$emit("showChatWidget", true);
        } else {
          if (!this.curr_step.step.endsWith("Disclaimer")) {
            if (this.curr_step.step.includes("#")) {
              this.$EventBus.$emit("gtag", {
                event: "konfigurator-frage",
                indikation: this.branch,
                schritt: "indikationsfragen",
                teilschritt: this.curr_step.step,
              });
            } else {
              this.$EventBus.$emit("gtag", {
                event: "konfigurator-frage",
                indikation: this.branch,
                schritt: this.curr_step.step,
              });
            }
          }
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }
        this.running = false;
      } else {
        let res = await response.json();
        if (res.error == "102") {
          this.$bvModal.show("timeout-modal");
        }
        // TODO
        //console.log(response.status);
      }
    },
    loadProductInfo: async function () {
      let input = {
        code: this.curr_step.code,
        lang: this.$i18next.resolvedLanguage,
      };
      let response = await fetch(
        this.$ZNC_BACKEND_BASE + "/business/v1/product",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(input),
        }
      );
      if (response.ok) {
        let result = await response.json();
        this.recipe = result.recipe;
        this.product = result.product;
        this.curr_step.step = "##SHOW_MIXTURE##";
        this.$EventBus.$emit("gtag", {
          event: "konfigurator-checkout",
          indikation: this.branch,
          schritt: "produktansicht",
          teilschritt: result.recipe.num,
        });
      } else {
        // TODO
        //console.log(response.status);
      }
    },
    go_back: function () {
      this.$EventBus.$emit("transition_direction", "backward");
      this.curr_step = this.history.pop();
      if (this.curr_step == undefined) {
        this.$router.push("/");
      } else {
        this.$EventBus.$emit(
          "update_progress",
          this.curr_step.index,
          this.curr_step.count
        );
      }
      Vue.nextTick(function () {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      });
    },
    go_start: function () {
      this.$EventBus.$emit("transition_direction", "backward");
      this.history.splice(0, this.history.length);
      this.curr_step = null;
      this.nextStep();
    },
    beforeTransition: function () {
      this.$EventBus.$emit("transition", "start");
    },
    afterTransitionEnter: function () {
      this.$EventBus.$emit("transition", "end");
      this.$EventBus.$emit("transition_direction", "foreward");
      let input = document.getElementById("input_question_input");
      if (input) input.focus();
    },
    afterTransitionLeave: function () {
      this.$EventBus.$emit("transition", "end");
    },
    touchstart: function (e) {
      if (
        window.pageYOffset < 5 &&
        document.getElementById("touch-wrapper").scrollTop < 5
      ) {
        this.touch.active = true;
        this.touch.startX = e.changedTouches[0].pageX;
        this.touch.startY = e.changedTouches[0].pageY;
        this.touch.startTime = new Date().getTime();
        this.touch.debug = "touch start";
      } else {
        this.touch.active = false;
      }
    },
    touchend: function (e) {
      if (this.touch.active) {
        let distX = e.changedTouches[0].pageX - this.touch.startX;
        let distY = e.changedTouches[0].pageY - this.touch.startY;
        let elapsedTime = new Date().getTime() - this.touch.startTime;
        this.touch.debug +=
          " end: " + distX + " " + distY + " elapsedTime: " + elapsedTime;
        if (distY >= 150 && distX < 100 && elapsedTime < 250) {
          this.touch.debug += " DO GO BACK";
          this.go_back();
          this.touch.active = false;
        }
      }
    },
  },
};
</script>

<template>
  <b-container fluid="xxl" class="my-5">
    <!-- Rezept -->
    <b-row class="pt-2" align-h="center">
      <b-col cols="12" class="text-left text-md-center">
        <h2>{{ $t("recipeTitle") }}</h2>
        <div class="my-3">
          {{ $t("recipeDesc") }}
        </div>
      </b-col>
      <b-col cols="4" md="2">
        <div class="heading f-h2">
          {{ formatCurrency(mixture.price) }}
        </div>
        <div class="f-p4 pt-1">
          {{ $t("taxInfo") }}
        </div>
      </b-col>
      <b-col cols="8" md="4">
        <b-button
          @click="$emit('checkout-action')"
          href="#"
          variant="primary"
          class="f-p1 px-5 w-100 text-uppercase heading text-center"
        >
          {{ $t("buttonRecipe") }}
        </b-button>
        <div class="f-p4 pt-1">
          {{ $t("deliveryTime", { count: mixture.details.deliverytime }) }},
          {{ $t("deliveryCosts") }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailPdfCheckout",
  props: ["mixture"],
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-container fluid="xxl" class="my-5">
    <!-- General Facts -->
    <b-row class="general-facts">
      <b-col cols="12"><hr class="seperator" /></b-col>
      <b-col cols="6" md="3" class="fact f-p3">
        <BIconClipboardCheck />{{ $t("fact.pharma") }}
      </b-col>
      <b-col cols="6" md="3" class="fact f-p3">
        <BIconGeo />{{ $t("fact.madeGermany") }}
      </b-col>
      <b-col cols="6" md="3" class="fact f-p3">
        <BIconFlower1 /> {{ $t("fact.natural") }}
      </b-col>
      <b-col cols="6" md="3" class="fact f-p3">
        <BIconBuilding /> {{ $t("fact.produce") }}
      </b-col>
      <b-col cols="12"><hr class="seperator" /></b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BIconBuilding,
  BIconClipboardCheck,
  BIconFlower1,
  BIconGeo,
} from "bootstrap-vue";

export default {
  name: "ProductDetailGeneralFacts",
  components: {
    BIconClipboardCheck,
    BIconFlower1,
    BIconGeo,
    BIconBuilding,
  },
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped>
.general-facts {
  .fact {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0;

    svg {
      width: 2rem;
      height: 2rem;
    }

    @media only screen and (min-width: 768px) {
      justify-content: center;
    }
  }

  .seperator {
    border-color: var(--zimply-black);
    border-width: 1.5px;
    width: 70%;
  }
}
</style>

<template>
  <b-container fluid="full">
    <h1 class="w-full">
      <template v-if="spray.type == 'Spray'">
        <span>{{ $t("titlePrefix") }} </span>
        <span>
          {{ recipe.mixture_num }}
        </span>
        <span v-if="hasInput"> {{ $t("titleAppendix") }}</span>
      </template>
      <template v-else-if="spray.details.intro">
        {{ $t("titlePrefixProduct") }}{{ spray.details.name }}
      </template>
      <template v-else>
        <div>
          {{ spray.num }}
        </div>
      </template>
    </h1>
    <p class="f-p2">
      {{ spray.details.intro }}
    </p>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailHeader",
  props: ["spray", "recipe", "hasInput"],
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

p {
  font-weight: 400;
  font-family: var(--text-montserrat);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row justify-content-start flex-wrap tags"},[_c('div',{staticClass:"tag f-p4"},[_c('BIconCurrencyEuro',{staticClass:"tag-icon"}),_vm._v(" "),_c('span',{staticClass:"tag-text"},[_c('i18next',{attrs:{"translation":_vm.$t('tagPriceADay')},scopedSlots:_vm._u([{key:"pricePerDay",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.formatCurrency(_vm.product.spray.details.price / 30))+"\n          ")]},proxy:true}])})],1)],1),_vm._v(" "),_c('div',{staticClass:"tag f-p4"},[_c('BIconTruck',{staticClass:"tag-icon"}),_vm._v(" "),_c('span',{staticClass:"tag-text"},[_vm._v(_vm._s(_vm.$t("deliveryCosts")))])],1),_vm._v(" "),_c('div',{staticClass:"tag f-p4"},[_c('BIconClockHistory',{staticClass:"tag-icon"}),_vm._v(" "),_c('span',{staticClass:"tag-text"},[_vm._v(_vm._s(_vm.$t("durationSpray")))])],1),_vm._v(" "),(_vm.vegan)?_c('div',{staticClass:"tag f-p4"},[_c('BIconFlower1',{staticClass:"tag-icon"}),_vm._v(" "),_c('span',{staticClass:"tag-text"},[_vm._v(_vm._s(_vm.$t("vegan")))])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"img-container position-relative text-center"},[_c('div',{staticClass:"position-absolute btn-share"},[_c('b-button',{attrs:{"href":"#","variant":"link"},on:{"click":function($event){return _vm.$emit('share-action')}}},[_c('BIconBoxArrowUp')],1)],1),_vm._v(" "),(
        _vm.product.spray.details.images &&
        _vm.product.spray.details.images.length > 0
      )?_c('div',{staticClass:"pl-4 pr-4"},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('div',{staticClass:"position-absolute prev-btn"},[_c('b-button',{attrs:{"href":"#","variant":"link"},on:{"click":function($event){return _vm.prev()}}},[_c('BIconChevronLeft')],1)],1),_vm._v(" "),_c('b-carousel',{ref:"imgCarousel",model:{value:(_vm.active_img),callback:function ($$v) {_vm.active_img=$$v},expression:"active_img"}},[(_vm.product.spray.type == 'Spray')?_c('b-carousel-slide',{staticClass:"generated-image",attrs:{"img-src":_vm.product.spray.details.images[0]}},[_c('canvas',{staticClass:"h-100",attrs:{"id":"generated-image-slider"},on:{"click":function($event){_vm.modal_img = 'g'}}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.product.spray.details.images),function(img,i){return _c('b-carousel-slide',{key:'img-' + i,attrs:{"img-src":img.includes('|') ? img.split('|')[1] : img}},[_c('div',{staticClass:"w-100 h-100",on:{"click":function($event){img.includes('|')
                  ? _vm.$bvModal.show('video-modal-' + i)
                  : (_vm.modal_img = i)}}})])})],2),_vm._v(" "),_c('div',{staticClass:"position-absolute next-btn"},[_c('b-button',{attrs:{"href":"#","variant":"link"},on:{"click":function($event){return _vm.next()}}},[_c('BIconChevronRight')],1)],1)],1),_vm._v(" "),(_vm.product.spray.type == 'Spray')?_c('div',{staticClass:"img-modal",class:_vm.modal_img == 'g' ? 'active' : '',on:{"click":function($event){_vm.modal_img = null}}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"modal-backdrop"})]):_vm._e(),_vm._v(" "),_vm._l((_vm.product.spray.details.images),function(img,i){return [(img.includes('|'))?_c('b-modal',{key:'video-modal-' + i,attrs:{"id":'video-modal-' + i,"centered":"","size":"xl","hide-footer":"","hide-header":""}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":img.split('|')[0],"allowfullscreen":""}})],1):_c('div',{key:'img-modal-' + i,staticClass:"img-modal",class:_vm.modal_img == i ? 'active' : '',on:{"click":function($event){_vm.modal_img = null}}},[_c('div',{staticClass:"img-modal-lightbox"},[_c('b-img',{attrs:{"src":img}})],1),_vm._v(" "),_c('div',{staticClass:"modal-backdrop"})])]}),_vm._v(" "),_c('b-container',{staticClass:"p-2",attrs:{"fluid":""}},[_c('b-row',[(_vm.product.spray.type == 'Spray')?_c('b-col',{staticClass:"pl-1 pr-1"},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('b-img',{staticClass:"preview-img",class:_vm.active_img == 0 ? 'active' : '',attrs:{"fluid":"","thumbnail":"","src":require("@/assets/spray-empty.png")},on:{"click":function($event){_vm.active_img = 0}}})],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.product.spray.details.images),function(img,i){return _c('b-col',{key:'tn-' + i,staticClass:"pl-1 pr-1"},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('b-img',{staticClass:"preview-img",class:_vm.active_img == i + (_vm.product.spray.type == 'Spray' ? 1 : 0)
                    ? 'active'
                    : '',attrs:{"fluid":"","thumbnail":"","src":img.includes('|') ? img.split('|')[1] : img},on:{"click":function($event){_vm.active_img = i + (_vm.product.spray.type == 'Spray' ? 1 : 0)}}})],1)],1)})],2)],1)],2):_c('div',{staticClass:"bottle-background"},[_c('canvas',{attrs:{"id":"generated-image-single"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-modal-lightbox"},[_c('canvas',{attrs:{"id":"generated-image-modal"}})])
}]

export { render, staticRenderFns }
<template>
  <ProductDetail
    v-if="recipe"
    :recipe="recipe"
    :product="product"
    :firstname="firstname"
    :branch="recipe.branch"
    :hasInput="false"
  />
</template>

<script>
import ProductDetail from "@/components/ProductDetail.vue";

export default {
  name: "ProductView",
  components: {
    ProductDetail,
  },
  props: ["product_num", "firstname"],
  metaInfo: function () {
    return {
      titleTemplate: "ZIMPLY NATURAL - %s",
    };
  },
  data: function () {
    return {
      recipe: null,
      product: null,
    };
  },
  created: async function () {
    await this.$sessionAvailable;
    this.loadProductInfo();
  },
  watch: {
    "$i18next.resolvedLanguage": function () {
      this.loadProductInfo();
    },
  },
  methods: {
    loadProductInfo: function () {
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: this.product_num,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            this.recipe = {
              mixture_num: result.recipe.num,
              mixture_content: result.recipe.content,
              mixture_dosage: result.recipe.dosage,
              branch: result.recipe.branch,
              scope: result.recipe.scope,
            };
            this.product = result.product;
            this.$EventBus.$emit("gtag", {
              event: "konfigurator-schnellbestellung",
              schritt: "produktansicht",
              teilschritt: this.product_num,
            });
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
              this.$router.replace({
                name: "Start",
              });
            } else {
              this.$EventBus.$emit(
                "show_error",
                this.$t("product.errorUnknownProduct")
              );
              this.$router.replace({
                name: "Start",
                params: { performSearch: this.product_num },
              });
            }
          }
        });
    },
  },
};
</script>
